<script setup lang="ts">
const emit = defineEmits(['update:page'])
const props = defineProps<{
  page: number
  max: number
}>()

const current = computed({
  get: () => props.page,
  set: (value) => emit('update:page', value),
})

const isTablet = useMediaQuery('(min-width: 768px)')
const maxPages = computed(() => (isTablet.value ? 6 : 3))

const next = () => {
  if (current.value === props.max) return
  current.value += 1
}
const previous = () => {
  if (current.value === 1) return
  current.value -= 1
}
</script>
<template>
  <nav class="pagination">
    <ClientOnly>
      <q-pagination v-model="current" class="label f-bold" :max="max" :max-pages="maxPages" boundary-numbers />
    </ClientOnly>
    <div class="navigation">
      <ButtonNavigation :disabled="current === 1" @click="previous" />
      <ButtonNavigation direction="right" :disabled="current === props.max" @click="next" />
    </div>
  </nav>
</template>
<style lang="scss">
.q-pagination {
  &__content,
  &__middle {
    display: flex;
    align-items: center;
  }
}
.q-btn {
  @include fluid-ts-ds(--size, 42px, 54px);

  width: var(--size);
  height: var(--size);

  border: 1px solid transparent;
  @include transitions(border-color, border-width, transform);
  @include hover {
    border-width: 2px;
    border-color: var(--c-blue-rollover);
    color: var(--c-blue-rollover);
    transform: scale(0.92);
  }

  &[aria-current='true'] {
    border-color: var(--c-primary);
    pointer-events: none;
  }
}
</style>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  color: var(--c-primary);
}
.navigation {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
</style>
